import React from "react";
import ConditionalComponent from "../../../components/ConditionalComponent";
import ReactMarkdown from "react-markdown";

interface ArticleContentProps {
    content: string;
    excerpt: string;
}

const ArticleContentComponent: React.FC<ArticleContentProps> = ({content, excerpt}) => {
    const style = "prose prose-xl"

    return <>
        <ConditionalComponent condition={!!content}>
            <ReactMarkdown className={style}>{content}</ReactMarkdown>
        </ConditionalComponent>
        <ConditionalComponent condition={!content}>
            <ReactMarkdown className={style}>{excerpt + '...'}</ReactMarkdown>
        </ConditionalComponent>
    </>
}

export default ArticleContentComponent;