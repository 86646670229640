import {useEffect, useState} from "react";
import fetchAbout from "../service/aboutMeService";

const useFetchAbout = () => {
    const [about, setAbout] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAbout()
            .then(fetchedAbout => {
                setAbout(fetchedAbout);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    return { about, loading };
};

export default useFetchAbout;