import {useEffect, useState} from "react";
import {Category} from "../../../models";
import {fetchCategories} from "../service/blogService";

const useFetchCategories = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [loadingCategories, setLoadingCategories] = useState<boolean>(true);

    useEffect(() => {
        fetchCategories()
            .then(fetchedCategories => {
                setCategories(fetchedCategories);
                setLoadingCategories(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingCategories(false);
            });
    }, []);

    return { categories, loadingCategories };
}

export default useFetchCategories;