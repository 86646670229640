export const API_URL = 'https://vic0e9qoyl.execute-api.eu-central-1.amazonaws.com/Prod/api';

export const addDays = (d: Date, days: number) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + days);
}

export const cacheItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify({
        value: JSON.stringify(value),
        expiryDate: addDays(new Date(), 1).toISOString()
    }));
}

export const getCachedItem = (key: string) => {
    const item = localStorage.getItem(key);
    if (!item) return null;
    const itemJSON = parseNullableJSON(item, null);
    if (!itemJSON || itemJSON.expiryDate < new Date()) return null;

    return parseNullableJSON(itemJSON.value, '');
}

export const fetchWithCache = async (key: string, fetchFunction: () => any) => {
    try {
        const cachedItem = getCachedItem(key);
        if (cachedItem !== null && cachedItem !== undefined && cachedItem !== '')
            return cachedItem;

        const fetchedItem = await fetchFunction();
        cacheItem(key, fetchedItem);

        return fetchedItem;
    } catch (error) {
        console.error(`Error fetching ${key}:`, error);
        throw error;
    }
};

export const parseNullableJSON = (json: string, backupValue: any) => {
    return json ? JSON.parse(json) : backupValue
}