import Loader from "./index";

interface LoadingProps {
  inProgress: boolean;
  children: React.ReactNode;
}

export const Loading: React.FC<LoadingProps> = ({inProgress, children}) => {
  return inProgress ?
    <div className="flex flex-col items-center absolute
        prose-lg justify-center text-white
        bg-gradient-to-b from-slate-900 to bg-slate-500
        gap-3 -inset-x-1/2 -inset-y-1/2 opacity-80">
      <Loader/>
      <span> Loading ... </span>
    </div> : <>{children}</>;
}