import {useEffect, useState} from "react";
import {Post} from "../../../models";
import {getSimilarPosts} from "../../../scenes/blog/service/queryService";

const useFetchSimilarPosts = (slug: string, categories: string[]) => {
    const [similarPosts, setSimilarPosts] = useState<Post[]>([]);

    useEffect(() => {
        getSimilarPosts(categories, slug)
            .then((posts) => {
                setSimilarPosts(posts);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [slug, categories]);

    return similarPosts;
}

export default useFetchSimilarPosts;