import React from "react";

interface AuthorSectionProps {
    name: string;
    photoUrl: string;
    role: string;
}

const ArticleAuthorComponent: React.FC<AuthorSectionProps> = ({name, photoUrl, role}) => {
    return <section className="flex flex-row items-center mb-3">
        <img alt={name}
             width="50px"
             className="rounded-full ml-4"
             src={photoUrl}/>
        <section className="flex flex-col">
            <p className="prose prose-xl ml-4">{name}</p>
            <p className="prose prose-l ml-4 text-slate-400">{role}</p>
        </section>
    </section>
}

export default ArticleAuthorComponent;