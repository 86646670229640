import CardBox from "./CardBox";
import ReactMarkdown from "react-markdown";
import {components} from "./MarkdownComponents";
import React from "react";
import {Loading} from "./Loader/Loading";

interface SimplePageProps {
  sections: string[];
  loading: boolean;
}

const SimplePage: React.FC<SimplePageProps> = ({sections, loading}) => {
  console.log('Ppportfolio', sections);

  return <Loading inProgress={loading}>
    <main className="flex flex-grow flex-col
                px-3 gap-4 overflow-y-auto
                bg-slate-200 pb-8 items-center">
      {sections.map(
        (section, index) =>
          <CardBox key={index}>
            <ReactMarkdown className="prose-lg pl-3" components={components}>
              {section.length > 0 ? section : ''}
            </ReactMarkdown>
          </CardBox>)
      }
    </main>
  </Loading>
}

export default SimplePage;