import React from "react";
import {SimplePage} from "../../components";
import useFetchExperience from "./hook/useFetchExperience";

const Experience = () => {
    const {experience, loading} = useFetchExperience();

    const sections = experience ?
        [experience.work, experience.certifications, experience.education]
        : ['Not found!'];

    return (
        <SimplePage sections={sections} loading={loading}/>
    );
}

export default Experience;