import React from "react";
import {CalendarIcon} from "../../../icons";
import moment from "moment/moment";

interface ArticleCreationProps {
    updatedAt: string;
}

const ArticleUpdateComponent: React.FC<ArticleCreationProps> = ({updatedAt}) => {
    return <section className="flex flex-row prose prose-m mb-5 ml-3 text-slate-400">
        <CalendarIcon/>
        <section className="ml-2">
            {moment(updatedAt).format('YYYY-MM-DD HH:MM:SS')}
        </section>
    </section>;
}

export default ArticleUpdateComponent;