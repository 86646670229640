import React from "react";
import {Post} from "../../../models";
import {SimilarPostsWidget} from "../../../components";
import CardBox from "../../../components/CardBox";
import ArticleReadMoreComponent from "./ArticleReadMoreComponent";
import ArticleContentComponent from "./ArticleContentComponent";
import ArticleUpdateComponent from "./ArticleUpdateComponent";
import ArticleHeaderComponent from "./ArticleHeaderComponent";
import ArticleAuthorComponent from "./ArticleAuthorComponent";
import {useNavigate} from "react-router-dom";

interface ContentCardProps {
  post: Post;
}

const ContentCard: React.FC<ContentCardProps> =
  ({post}) => {
    const navigate = useNavigate();

    const handleBackAction = () => {
      navigate(-1);
    };

    return <CardBox>
      <ArticleAuthorComponent
        name={post.author.name}
        photoUrl={post.author.photo.url}
        role={post.author.role}/>
      <ArticleHeaderComponent post={post}/>
      <ArticleUpdateComponent updatedAt={post.updatedAt}/>

      <section className="flex flex-col pl-2 pr-2 gap-10 mb-3 items-center">
        <ArticleContentComponent content={post.content} excerpt={post.excerpt}/>
        <SimilarPostsWidget categories={post.categories.map(c => c.name)} slug={post.slug}/>
        {!post.content
          ? <ArticleReadMoreComponent slug={post.slug}/>
          : <div className="transition duration-500 transform hover:-translate-y-1
                        inline-block bg-slate-700 prose-lg rounded-full text-center
                        w-24
                        px-8 py-2 cursor-pointer text-yellow-500" onClick={handleBackAction}>
            Back
          </div>}
      </section>
    </CardBox>
  };

export default ContentCard;