import {useEffect, useState} from "react";
import fetchPortfolio from "../service/portfolioService";

const useFetchPortfolio = () => {
    const [portfolio, setPortfolio] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPortfolio()
            .then(fetchedPortfolio => {
                setPortfolio(fetchedPortfolio);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    return {portfolio, loading};
}

export default useFetchPortfolio;