import ContentCard from "./components/ContentCard";
import React from "react";
import {CategoriesWidget} from "../../components";
import {Loading} from "../../components/Loader/Loading";
import useFetchPosts from "./hook/useFetchPosts";
import useFetchCategories from "./hook/useFetchCategories";

const BlogDashboard = () => {
  const {posts, loadingPosts} = useFetchPosts();
  const {categories, loadingCategories} = useFetchCategories();

  return <Loading inProgress={loadingPosts || loadingCategories}>
    <section className="flex flex-grow flex-col px-3 gap-4 items-center
                bg-slate-200 pb-8">
      {posts.map(
        (post) =>
          <ContentCard post={post} key={post.title}/>
      )}
      {categories.length > 0 && <CategoriesWidget categories={categories}/>}
    </section>
  </Loading>;
}

export default BlogDashboard;