import React from "react";
import ContactLinkComponent from "./components/ContactLinkComponent";


const Contact = () => {
    const linkedInUrl = "https://www.linkedin.com/in/alinv";
    const mailLink = "alin.p.voinea@gmail.com";

    return <main className="flex flex-grow flex-col px-3 gap-4 overflow-y-auto
                bg-slate-200 pb-8 items-center">
        <ContactLinkComponent url={linkedInUrl}/>
        <ContactLinkComponent url={mailLink} isMail={true}/>
    </main>;
}

export default Contact;