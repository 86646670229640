import axios from "axios";
import {API_URL} from "./common";

export const queryGraphQL = async (query: string, variables: {}) => {
    let queryPayload = {
        action: 'Query',
        query: query,
        ...(Object.keys(variables).length && {variables: variables})
    };

    console.log('Query payload', queryPayload);

    try {
        const response = await axios.post(
            API_URL,
            queryPayload,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        console.log('Response', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data for query', query, error);
        return [];
    }
}

export const get = async (query: string) => {
    try {
        const response = await queryGraphQL(query, {});

        if (!response) {
            console.log('No response from the server');
            return '';
        }

        return response;
    } catch (error) {
        console.log('error:' + error)
    }

    return '';
}