import {Link} from "react-router-dom";
import moment from "moment";
import React from "react";
import {Post} from "../../models";

interface SimilarPostEntryProps {
    post: Post;
}

const SimilarPostEntry: React.FC<SimilarPostEntryProps> = ({post}) => {
    return <li key={post.slug}>
        <section className="flex flex-col">
            <Link to={`/post/${post.slug}`} style={{textDecoration: 'none'}}>
                <section className="prose prose-sm text-slate-400">
                    {moment(post.updatedAt).format('YYYY-MM-DD')}
                </section>
                <div>{post.title}</div>
            </Link>
        </section>
    </li>
}

export default SimilarPostEntry;