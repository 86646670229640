import {get} from "../../../services/queryService";
import {QUERY_ABOUT} from "../data/query";
import {fetchWithCache} from "../../../services/common";

const CACHE_KEY = "about";

const fetchAbout = (): Promise<string> => fetchWithCache(CACHE_KEY, getAbout);

const getAbout = async () => {
    const response = await get(QUERY_ABOUT);
    return response.abouts[0].text;
}

export default fetchAbout;