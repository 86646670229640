export const QUERY_POSTS = `
    query Assets {
      postsConnection {
        edges {
          node {
            author {
              bio
              id
              name,
              role,
              photo {
                url
              }
            }
            createdAt
            slug
            title
            excerpt
            featuredImage {
              url,
              source
            }
            categories {
              name
              slug
            }
          }
        }
      }
    }`;

export const QUERY_POST_DETAILS = `
    query GetPostDetails {
      posts(
        orderBy: createdAt_ASC
        last: 3
      ) {
        title
        featuredImage{
          url
        }
        createdAt
        slug
      }
    }`;

export const QUERY_FULL_POST_CONTENT = `
  query GetFullPostContent($slug: String!) {
    post(where: { slug: $slug }) {
      content
    }
  }
`;

export const QUERY_CATEGORIES = `
        query GetCategories {
          categories {
            name
            slug
          }
        }
    `;

export const QUERY_SIMILAR_POSTS = `
        query GetSimilarPosts($categories: [String!], $slug: String!) {
          posts(
            orderBy: createdAt_DESC
            first: 5
            where: {
              categories_some: {
                name_in: $categories
              },
              slug_not: $slug
            }
          ) {
            title
            featuredImage {
              url
            }
            createdAt
            slug
          }
        }
    `;