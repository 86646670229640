import React from "react";

interface ConditionalComponentProps {
    condition: boolean;
    children: React.ReactNode;
}

const ConditionalComponent: React.FC<ConditionalComponentProps> = ({condition, children}) => {
    return condition ? <>{children}</> : <></>;
}

export default ConditionalComponent;