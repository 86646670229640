import React, {useState} from "react";
import DropdownHeaderMenu from "../Sidebar/DropdownHeaderMenu";
import {MenuIcon} from "../../icons";

const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen((prev) => !prev);
    }

    return <section
        className="flex items-center justify-between mb-1 bg-gradient-to-r from-slate-900 to bg-slate-500 z-50">
        <section className="flex flex-col">
            <a href="/">
                <section className="flex flex-row items-center">
                    <img src="/assets/logo192.png" alt="Alin Voinea"
                         className="max-sm:w-10 max-sm:h-10 w-20 h-20 mt-3 ml-3 mr-3"/>
                    <p className="text-slate-200 sm:text-2xl md:text-3xl">Alin Voinea</p>
                </section>
            </a>
            <p className="subpixel-antialiased text-slate-200 hidden sm:block ml-3 mr-3 mb-2">
                Professional Software Engineer
            </p>
        </section>
        <section className="flex-col block sm:hidden">
            <button className="ml-3 mr-3 rounded-lg border-2 border-transparent active:border-white duration-300"
                    onClick={toggleMenu}>
                <MenuIcon/>
            </button>
        </section>
        {isOpen && <DropdownHeaderMenu toggleMenu={toggleMenu}/>}
    </section>;
}

export default Header;