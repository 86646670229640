import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "../Sidebar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
  return (
    <section className="flex flex-col min-h-screen">
      <Header/>

      <section className="flex flex-1">
        <Sidebar/>
        {children}
      </section>

      <Footer/>
    </section>
  );
}

export default Layout;