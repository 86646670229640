import React from "react";
import {MenuEntries} from "./MenuEntries";


interface DropdownHeaderMenuProps {
    toggleMenu: () => void;
}

const DropdownHeaderMenu: React.FC<DropdownHeaderMenuProps> = ({toggleMenu}) => {
    return <div
        className="flex flex-col absolute rounded-lg mt-4 top-10 text-slate-100 items-start w-full bg-opacity-70 bg-gradient-to-r from-slate-900 to bg-slate-500 block sm:hidden">
        <div className="flex flex-col gap-2 p-4" onBlur={toggleMenu}>
            <MenuEntries isOpen={true} onClick={toggleMenu}/>
        </div>
    </div>
}

export default DropdownHeaderMenu;