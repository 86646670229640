import SidebarItem from "./SidebarItem";
import {AboutMeIcon, BlogIcon, ContactIcon, ExperienceIcon, PortfolioIcon} from "../../icons";
import React from "react";

interface SidebarItemProps {
    isOpen: boolean;
    onClick?: () => void;
}

export const AboutMenu: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    return <SidebarItem onClick={onClick} isOpen={isOpen} title="About me" url="/aboutme" Icon={AboutMeIcon}/>;
}

export const BlogMenu: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    return <SidebarItem onClick={onClick} isOpen={isOpen} title="Blog" url="/blog" Icon={BlogIcon}/>;
}

export const ExperienceMenu: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    return <SidebarItem onClick={onClick} isOpen={isOpen} title="Experience" url="/experience" Icon={ExperienceIcon}/>;
}
export const PortfolioMenu: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    return <SidebarItem onClick={onClick} isOpen={isOpen} title="Portfolio" url="/portfolio" Icon={PortfolioIcon}/>;
}

export const ContactMenu: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    return <SidebarItem onClick={onClick} isOpen={isOpen} title="Contact" url="/contact" Icon={ContactIcon}/>;
}

export const MenuEntries: React.FC<SidebarItemProps> = ({isOpen, onClick}) => {
    const menuComponents = [
        { component: AboutMenu, key: 'about' },
        { component: BlogMenu, key: 'blog' },
        { component: ExperienceMenu, key: 'experience' },
        { component: PortfolioMenu, key: 'portfolio' },
        { component: ContactMenu, key: 'contact' },
    ];

    return (
        <>
            {menuComponents.map(({ component: MenuComponent, key }, index) => (
                <React.Fragment key={key}>
                    <MenuComponent isOpen={isOpen} onClick={onClick} />
                    {index < menuComponents.length - 1 && <hr className="border-slate-300" />}
                </React.Fragment>
            ))}
        </>
    );
}