import React from "react";
import {Link} from "react-router-dom";
import {Post} from "../../../models";

interface ArticleTitleProps {
    slug: string;
    title: string;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({slug, title}) => {
    return <Link to={`/post/${slug}`} style={{textDecoration: 'none'}}>
        <h1 className="text-3xl font-bold
        transition duration-500 cursor-pointer
        hover:text-yellow-600 prose prose-l">
            {title}
        </h1>
    </Link>;
}

interface ArticleImageProps {
    url: string;
    title: string;
    imageSource: string;
}

const ArticleImage: React.FC<ArticleImageProps> = ({url, title, imageSource}) => {
    return <>
        <img src={url} alt={title}
             className="h-40 lg:h-80 object-cover shadow-lg rounded-t-lg rounded-lg"
        />
        <span className="prose-sm text-slate-400">Image source: {imageSource}</span>
    </>
}

interface ArticleHeaderProps {
    post: Post;
}

const ArticleHeaderComponent: React.FC<ArticleHeaderProps> = ({post}) => {
    return <section className="flex flex-col ml-3 mr-4 gap-2 mb-5">
        <ArticleImage url={post.featuredImage.url} title={post.title} imageSource={post.featuredImage.source}/>
        <ArticleTitle title={post.title} slug={post.slug}/>
        <section className="text-slate-400 italic">
            <h3>in {post.categories.map(c => c.name)}</h3>
        </section>
    </section>;
}

export default ArticleHeaderComponent;