import {useEffect, useState} from "react";
import {Post} from "../../../models";
import {fetchPosts} from "../service/blogService";

const useFetchPosts = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [loadingPosts, setLoadingPosts] = useState<boolean>(true);

    useEffect(() => {
        fetchPosts()
            .then(fetchedPosts => {
                setPosts(fetchedPosts);
                setLoadingPosts(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingPosts(false);
            });
    }, []);

    return { posts, loadingPosts };
}

export default useFetchPosts;