import {Post} from "../../../models";
import {queryGraphQL} from "../../../services/queryService";
import {
    QUERY_CATEGORIES,
    QUERY_FULL_POST_CONTENT,
    QUERY_POST_DETAILS,
    QUERY_POSTS,
    QUERY_SIMILAR_POSTS
} from "../data/queries";

interface Edge {
    node: Post;
}

export async function getPosts(): Promise<Post[]> {
    try {
        const response = await queryGraphQL(QUERY_POSTS, {});

        if (!response) {
            console.log('No response from the server');
            return [];
        }

        const posts = response.postsConnection.edges.map((edge: Edge) => edge.node);

        // Sort the posts in descending order by createdAt
        posts.sort((a: Post, b: Post) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

        return posts;
    } catch (error) {
        console.log('error:' + error)
    }
    return [];
}

export const unpackRetrievedPosts = async (query: string) => {
    try {
        const response = await queryGraphQL(query, {});

        if (!response) {
            console.log('No response from the server');
            return [];
        }

        const {data} = response;
        const posts = data.posts;
        console.log(posts);

        return posts;
    } catch (error) {
        console.log('error:' + error)
    }
    return [];
}

export const getFullPostContent = async (slug: string) => {
    const response = await queryGraphQL(QUERY_FULL_POST_CONTENT, {slug});
    return response.post.content;
}

export async function getPostDetails(): Promise<Post[]> {
    return unpackRetrievedPosts(QUERY_POST_DETAILS);
}


export async function getSimilarPosts(categories: string[], slug: string): Promise<Post[]> {
    const queryParams = {
        categories,
        slug
    };

    let response = await queryGraphQL(QUERY_SIMILAR_POSTS, queryParams);
    return response.posts;
}

export const getCategories = async () => {
    console.log("Getting categories");
    try {
        const response = await queryGraphQL(QUERY_CATEGORIES, {});
        return response.categories;
    } catch (error) {
        console.error(error);
        return [];
    }
}