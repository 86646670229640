import React from "react";

const Footer = () => {
    const startYear = 2024;
    const currentYear = new Date().getFullYear();
    const rangeString = startYear === currentYear ? `${startYear}` : `${startYear} - ${currentYear}`;
    return <p className="w-full prose prose-sm text-left pl-3">
        Copyright © {rangeString} Alin Voinea. All rights reserved.
    </p>;
}

export default Footer;