import {useEffect, useState} from "react";
import ExperienceModel from "../model/ExperienceModel";
import fetchExperience from "../service/experienceService";

const useFetchExperience = () => {
    const [experience, setExperience] = useState<ExperienceModel | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchExperience()
            .then(fetchedExperience => {
                setExperience(fetchedExperience);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    return {experience, loading};
}

export default useFetchExperience;