import React from "react";

interface CardBoxInterface {
    children: React.ReactNode;
}

const CardBox: React.FC<CardBoxInterface> = ({children}) => {
    return <section className="flex flex-col ml-4 mr-4 mt-1 mb-1
    gap-3 bg-white shadow-lg rounded-lg p-3 lg:w-2/3 w-5/6">
        {children}
    </section>
}

export default CardBox;