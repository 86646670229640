import {QUERY_EXPERIENCE} from "../data/query";
import {get} from "../../../services/queryService";
import {fetchWithCache} from "../../../services/common";
import ExperienceModel from "../model/ExperienceModel";

const CACHE_KEY = "experience";

const fetchExperience = (): Promise<ExperienceModel> =>
    fetchWithCache(CACHE_KEY, getExperience);

const getExperience = async (): Promise<ExperienceModel> => {
    const response = await get(QUERY_EXPERIENCE);
    return {
        work: response.experiences[0].work,
        education: response.experiences[0].education,
        certifications: response.experiences[0].certifications
    };
}

export default fetchExperience;