import {TailSpin} from "react-loader-spinner";

const Loader = () => {
    return (
        <TailSpin
            height="100"
            width="100"
            color="#a96f00"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
    );
};
export default Loader;