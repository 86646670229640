import React from 'react';

interface SidebarItemProps {
    isOpen: boolean;
    title: string;
    url: string
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
}


const SidebarItem: React.FC<SidebarItemProps> =
    ({
         isOpen,
         title,
         url,
         Icon,
         onClick
     }) => {
        const sidebarItemClass = "flex flex-row gap-1 rounded-lg border-2 border-transparent active:border-white duration-300";
        return (
            <a href={url}>
                <section onClick={onClick} className={sidebarItemClass}>
                    <Icon/>
                    {isOpen && <p>{title}</p>}
                </section>
            </a>
        );
    };

export default SidebarItem;