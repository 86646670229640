import {SocialIcon} from "react-social-icons";
import React from "react";
import {Link} from "react-router-dom";
import CardBox from "../../../components/CardBox";

interface ContactLinkProps {
    url: string;
    isMail?: boolean;
}

const ContactLinkComponent: React.FC<ContactLinkProps> = ({url, isMail = false}) => {
    const linkUrl = isMail ? "mailto:" + url : url;
    return <CardBox>
        <Link to={linkUrl} className="flex flex-col gap-2">
            <SocialIcon url={linkUrl}/>
            <span>{url}</span>
        </Link>
    </CardBox>;
}

export default ContactLinkComponent;