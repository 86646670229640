import {QUERY_PORTFOLIO} from "../data/query";
import {get} from "../../../services/queryService";
import {fetchWithCache} from "../../../services/common";

const CACHE_KEY = "portfolio";

const fetchPortfolio = (): Promise<string> =>
  fetchWithCache(CACHE_KEY, getPortfolio);

const getPortfolio = async () => {
  const response = await get(QUERY_PORTFOLIO);
  return response.portfolios[0].text;
}

export default fetchPortfolio;