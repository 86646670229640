import React from "react";
import {Link} from "react-router-dom";
import {Category} from "../models";
import CardBox from "./CardBox";


interface CategoriesWidgetProps {
    categories: Category[];
}

const CategoriesWidget: React.FC<CategoriesWidgetProps> = ({categories}) => {

    return (
        <CardBox>
            <section className="inline-block p-3 prose-lg">
                <h3>Categories</h3>
                <section className="flex flex-col">
                    {categories.map(category => (
                        <Link key={category.slug} to={`/category/${category.slug}`}>
                                <span className="cursor-pointer">
                                    {category.name}
                                </span>
                        </Link>
                    ))}
                </section>
            </section>
        </CardBox>
    );
}

export default CategoriesWidget;