import React from "react";
import ConditionalComponent from "../ConditionalComponent";
import useFetchSimilarPosts from "./hook/useFetchSimilarPosts";
import SimilarPostEntry from "./SimilarPostEntry";


interface PostWidgetProps {
    categories: string[];
    slug: string;
}

const SimilarPostsWidget: React.FC<PostWidgetProps> = ({categories, slug}) => {
    const similarPosts = useFetchSimilarPosts(slug, categories);
    const widgetTitle = `Also in ${categories}`;

    return (<ConditionalComponent condition={similarPosts && similarPosts.length > 0}>
            <section className="flex flex-col prose">
                <h3>{widgetTitle}</h3>
                <ul>
                    {similarPosts.map((post) => (
                        <SimilarPostEntry post={post} key={post.slug}/>
                    ))}
                </ul>
            </section>
        </ConditionalComponent>
    )
}

export default SimilarPostsWidget;