import React from "react";
import {Link} from "react-router-dom";

interface ArticleReadMoreProps {
    slug: string;
}

const ArticleReadMoreComponent: React.FC<ArticleReadMoreProps> = ({slug}) => {
    return <Link to={`/post/${slug}`}
                 className="transition duration-500 transform hover:-translate-y-1
                                     inline-block bg-slate-700 prose-lg rounded-full text-center
                                     px-8 py-2 cursor-pointer text-yellow-500">
        Read more
    </Link>
}

export default ArticleReadMoreComponent;