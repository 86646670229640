import ContentCard from "./ContentCard";
import React, {useEffect, useState} from "react";
import {Post} from "../../../models";
import {useParams} from "react-router-dom";
import {fetchFullPostContent, fetchPosts} from "../service/blogService";
import {Loading} from "../../../components/Loader/Loading";

const ArticleCard: React.FC = () => {
  const {slug} = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!slug) {
      console.error('Slug is undefined');
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const fetchedPosts = await fetchPosts();
        const foundPost = fetchedPosts.find(post => post.slug === slug) || null;

        setPost(foundPost);

        if (foundPost) {
          const fetchedContent = await fetchFullPostContent(slug);
          setPost({...foundPost, content: fetchedContent}); // Assuming you want to merge content
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <Loading inProgress={loading}>
      <section className="flex flex-grow flex-col px-3 gap-4 items-center overflow-y-auto bg-slate-200 pb-8">
        {post && <ContentCard post={post}/>}
      </section>
    </Loading>
  );
};

export default ArticleCard;
