import React from 'react';
import './App.css';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Layout} from "./components";
import {AboutMe, BlogDashboard, Contact, Experience, Portfolio, PostCard} from "./scenes";

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<BlogDashboard/>}/>
                    <Route path="/post/:slug" element={<PostCard/>}/>
                    <Route path="/aboutme" element={<AboutMe/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/experience" element={<Experience/>}/>
                    <Route path="/portfolio" element={<Portfolio/>}/>
                    <Route path="/blog" element={<BlogDashboard/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;