import React, {useState} from 'react';
import {MenuEntries} from "./MenuEntries";

const Sidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <section
            className={`flex-col transition-all hidden sm:block ${isOpen ? 'w-96' : 'w-16'} bg-gradient-to-b from-slate-900 to bg-slate-500`}>
            <section className="flex flex-row">
                <section className="flex flex-grow flex-col items-center gap-4 text-slate-300 ml-5 mt-20 mb-10">
                    {isOpen ?
                        <>
                            <img src="/assets/profile.jpeg" alt="Profile"
                                 className="w-24 h-24 rounded-full"/>
                            <section className="text-3xl">Alin Voinea</section>
                        </> : null
                    }
                </section>
                <section className="mt-3 mr-3">
                    <button onClick={toggleSidebar}>
                        <p className="text-slate-300 text-xl">
                            {isOpen ? '<' : '>'}
                        </p>
                    </button>
                </section>
            </section>

            <section className="flex flex-col text-slate-100 p-4 gap-5">
                <MenuEntries isOpen={isOpen}/>
            </section>
        </section>
    );
};

export default Sidebar;
