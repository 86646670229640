import React from "react";
import {SimplePage} from "../../components";
import useFetchAboutMe from "./hook/useFetchAboutMe";

const AboutMe = () => {
    const {about, loading} = useFetchAboutMe();

    return <SimplePage sections={[about]} loading={loading}/>;
}

export default AboutMe;